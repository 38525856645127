<template>
    <div class="payment-common">
        <b-form-group :disabled="disabled">
            <PaymentMultipleItems label="tiền mặt" :paymentType="PAYMENT_TYPE.CASH" :items="cash" :disabled="disabled"
                :options="filteredCashAccOptions" :validate="validateCash" :max="0"
                @select="onSelectedMul($event, PAYMENT_TYPE.CASH)" @change="onInputChange($event, PAYMENT_TYPE.CASH)">
                <template #bind-money="{ index, disabled }">
                    <b-button :disabled="disabled" style="width: 40px" variant="secondary" size="sm"
                        @click="bindingIncomingMoney(index, PAYMENT_TYPE.CASH)">
                        <i class="fas fa-arrow-right"></i>
                    </b-button>
                </template>
            </PaymentMultipleItems>
        </b-form-group>
        <b-form-group :disabled="disabled">
            <PaymentMultipleItems label="chuyển khoản" :paymentType="PAYMENT_TYPE.TRANSFER" :items="transfer"
                :disabled="disabled" :options="filteredTransferAccOptions" :validate="validateTransfer"
                @change="onInputChange($event, PAYMENT_TYPE.TRANSFER)"
                @select="onSelectedMul($event, PAYMENT_TYPE.TRANSFER)">
                <template #bind-money="{ index, disabled }">
                    <b-button :disabled="disabled" style="width: 40px" variant="secondary" size="sm"
                        @click="bindingIncomingMoney(index, PAYMENT_TYPE.TRANSFER)">
                        <i class="fas fa-arrow-right"></i>
                    </b-button>
                </template>
                <template #other="{ item, check, disabled }">
                    <b-form-group v-if="check(item)" :disabled="disabled" class="mb-2">
                        <b-form-input class="input-style" size="sm" autocomplete="off" v-model="item.paymentCode"
                            placeholder="Mã tham chiếu"></b-form-input>
                    </b-form-group>
                </template>
            </PaymentMultipleItems>
        </b-form-group>
        <b-button variant="secondary" size="sm" class="font-weight-bolder mb-2" :disabled="disabled"
            @click="popupModalPayment">
            <i class="fa fa-plus-circle"></i>
            Thêm thanh toán</b-button>
        <div>
            <PaymentAdditionModal />
        </div>
    </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import PaymentMultipleItems from '@/view/components/payments/PaymentMultipleItems.vue';
import PaymentAdditionModal from '@/view/components/payments/PaymentAdditionModal.vue';
import { PAYMENT_TYPE } from '@/utils/enum';
import {
    removeAccents,
    unMaskPrice,
    cloneDeep,
} from '@/utils/common';


export default {
    props: [
        'finalAmount',
        'stockDebtData',
        'disabled',
    ],
    watch: {
        'stockDebtData.storeId': {
            handler: async function (newValue) {
                this.paymentInfo.cashAccountantName = '';
                this.paymentInfo.cashAccountCode = '';
                this.paymentInfo.cashAccountId = null;
                this.filteredCashAccOptions = [];
                if (newValue) this.fetchCashAcc();
            },
        },
        stockDebtData: {
            handler: function (newStockDebtData) {
                let isChanged = false
                const transfer = newStockDebtData.transfer
                const cash = newStockDebtData.cash
                for (const [index, element] of transfer.entries()) {
                    if (JSON.stringify(element) !== JSON.stringify(this.transfer[index])) {
                        isChanged = true
                        break;
                    }
                }
                for (const [index, element] of cash.entries()) {
                    if (JSON.stringify(element) !== JSON.stringify(this.cash[index])) {
                        isChanged = true
                        break;
                    }
                }
                if (isChanged) {
                    this.convertPaymentFromStockDebt()
                }
            },
            deep: true,
            immediate: false,
        },
        cash: {
            handler: function () {
                this.handleEmitPaymentMul(this.cash, PAYMENT_TYPE.CASH);
            },
            deep: true,
        },
        transfer: {
            handler: function () {
                this.handleEmitPaymentMul(this.transfer, PAYMENT_TYPE.TRANSFER);
            },
            deep: true,
        },
        paymentInfo: {
            handler: function (newValue) {
                const paymentInfo = cloneDeep(newValue);
                this.$emit('receive-payment', paymentInfo);
            },
            deep: true,
        },

    },
    data() {
        return {
            PAYMENT_TYPE,
            cash: [],
            transfer: [],
            filteredCashAccOptions: [],
            filteredTransferAccOptions: [],
            cashAccOptions: [],
            transferOptions: [],
            paymentInfo: {
                //tiền mặt
                cashAmount: 0,
                cashAccountantName: '',
                cashAccountCode: '',
                cashAccountId: null,
                //chuyển khoản
                transferAmount: 0,
                transferAccountantName: '',
                transferAccountCode: '',
                transferAccountantId: null,
                transferReferenceCode: '',
            },
        };
    },
    components: {
        PaymentMultipleItems,
        PaymentAdditionModal,
    },
    created() {
        this.getDefaultValuePayment();
    },
    mounted() {
        this.fetchAccountant();
    },

    computed: {
        validateCash() {
            if (
                unMaskPrice(this.paymentInfo.cashAmount) > 0 &&
                !this.paymentInfo.cashAccountId
            ) {
                return false;
            }
            return true;
        },
        validateTransfer() {
            if (
                unMaskPrice(this.paymentInfo.transferAmount) > 0 &&
                !this.paymentInfo.transferAccountantId
            ) {
                return false;
            }
            return true;
        },
    },
    methods: {
        fetchCashAcc: async function () {
            if (!this.stockDebtData.storeId) {
                return;
            }
            const params = {
                storeId: this.stockDebtData.storeId,
                //  tk tien mat
                type: PAYMENT_TYPE.CASH,
            };
            ApiService.query('accountants/getByStore', { params }).then(
                (response) => {
                    this.cashAccOptions = [];
                    response.data.data.forEach((item) => {
                        if (item.level >= 2) {
                            const account = {
                                id: item.id,
                                name: item.name,
                                code: item.code,
                            };
                            this.cashAccOptions.push(account);
                        }
                    });
                    this.filteredCashAccOptions = this.cashAccOptions;

                },
            );
        },
        fetchAccountant: async function () {
            const params = {
                //  tk ngan hang
                type: PAYMENT_TYPE.TRANSFER,
            };
            ApiService.query('accountants/getByType', { params }).then((response) => {
                response.data.data.forEach((item) => {
                    if (item.level >= 2) {
                        const account = {
                            id: item.id,
                            name: item.name,
                            code: item.code,
                            accountBankNo: item.accountBankNo,
                        };
                        this.transferOptions.push(account);
                    }
                });
                this.filteredTransferAccOptions = this.transferOptions;
                if (this.transfer.length) {
                    for (let index = 0; index < this.transfer.length; index++) {
                        const element = this.transfer[index];
                        const transferItem = this.filteredTransferAccOptions.find(
                            (acc) => Number(acc.id) === element.paymentRefId,
                        );
                        if (transferItem) {
                            element.accountBankNo = transferItem.accountBankNo;
                        }
                    }
                }
            });
        },
        popupModalPayment() {
            this.$bvModal.show('modal-addition-payment');
        },
        onInputChange(option, type) {
            switch (type) {
                case PAYMENT_TYPE.CASH: {
                    this.filteredCashAccOptions = this.onFilterOptions(
                        this.cashAccOptions,
                        option.text,
                    );
                    if (this.isTextNul(option.text)) {
                        this.paymentInfo.cashAccountId = null;
                        //mul
                        this.cash[option.index].paymentRefId = null;
                        this.cash[option.index].paymentRefCode = null;
                        this.cash[option.index].paymentName = null;
                    }
                    break;
                }
                case PAYMENT_TYPE.TRANSFER: {
                    this.filteredTransferAccOptions = this.onFilterOptions(
                        this.transferOptions,
                        option.text,
                    );
                    if (this.isTextNul(option.text)) {
                        this.paymentInfo.transferAccountantId = null;
                        //mul
                        this.transfer[option.index].paymentRefId = null;
                        this.transfer[option.index].paymentRefCode = null;
                        this.transfer[option.index].paymentName = null;
                    }
                    break;
                }
            }
        },

        convertPaymentFromStockDebt() {
            const stockDebtPayment = this.stockDebtData.paymentInfo;
            this.paymentInfo = cloneDeep(stockDebtPayment);
            if (this.stockDebtData.transfer.length) {
                this.transfer = cloneDeep(this.stockDebtData.transfer);
            }
            if (this.stockDebtData.cash.length) {
                this.cash = cloneDeep(this.stockDebtData.cash);
            }
            this.fetchCashAcc();
        },
        onSelectedMul(option, type) {
            if (!option) {
                return;
            }
            let payments = [];
            switch (type) {
                case PAYMENT_TYPE.TRANSFER: {
                    payments = this.transfer;
                    break;
                }
                case PAYMENT_TYPE.CASH: {
                    payments = this.cash;
                    break;
                }
            }
            payments[option.index].paymentRefId = option.item.id;
            payments[option.index].paymentRefCode = option.item.code;
            payments[option.index].paymentName = option.item.name;
            payments[option.index].accountBankNo = option.item.accountBankNo;
        },
        bindingIncomingMoney(index, type) {
            const sumCustomerPay = this.finalAmount || 0;
            const sumPayments = this.sumPaymentAmountByType(index, type);
            const remain = sumCustomerPay - sumPayments;
            if (remain < 0) {
                return;
            }
            switch (type) {
                case PAYMENT_TYPE.CASH: {
                    this.cash[index].paymentAmount = remain;
                    break;
                }
                case PAYMENT_TYPE.TRANSFER: {
                    this.transfer[index].paymentAmount = remain;
                    break;
                }
            }
        },
        sumPaymentAmountByType(index, type) {
            let payments = [];
            switch (type) {
                case PAYMENT_TYPE.CASH: {
                    const cash = cloneDeep(this.cash);
                    cash.splice(index, 1);
                    payments = [cash, this.transfer];
                    break;
                }
                case PAYMENT_TYPE.TRANSFER: {
                    const transfer = cloneDeep(this.transfer);
                    transfer.splice(index, 1);
                    payments = [this.cash, transfer];
                    break;
                }
            }

            return payments.flat().reduce((result, item) => {
                const unMask = unMaskPrice(item.paymentAmount || 0);
                return result + Number(unMask);
            }, 0);
        },
        handleEmitPaymentMul(payments, type) {
            this.$emit('receive-payment-mul', payments, type);
        },
        getDefaultValuePayment() {
            const defaultValue = {
                id: this.$uuid.v4(),
                paymentRefId: null,
                paymentRefCode: null,
                paymentName: null,
                paymentAmount: null,
                paymentCode: null,
                transactionId: null,
            };
            this.transfer = [
                {
                    ...defaultValue,
                    paymentType: PAYMENT_TYPE.TRANSFER,
                },
            ];
            this.cash = [
                {
                    ...defaultValue,
                    paymentType: PAYMENT_TYPE.CASH,
                },
            ];
        },
        onFilterOptions(options, text) {
            const filteredData = options
                .filter((item) => {
                    return (
                        removeAccents(item.name)
                            .toLowerCase()
                            .indexOf(removeAccents(text.toLowerCase())) > -1
                    );
                })
                .slice(0, this.limit);

            return filteredData;
        },
    },
};
</script>

<style lang="scss">
.payment-common {
    .generate-qr-button {
        width: -webkit-fill-available;
        margin-top: 4px;
        margin-bottom: 4px;
        font-weight: 500;
    }

    .btn-circle {
        width: 30px;
        height: 30px;
        text-align: center;
        padding: 6px 0;
        font-size: 12px;
        line-height: 1.428571429;
        border-radius: 15px;
    }

    .btn-circle.btn-lg {
        width: 50px;
        height: 50px;
        padding: 10px 16px;
        font-size: 18px;
        line-height: 1.33;
        border-radius: 25px;
    }

    .btn-circle.btn-xl {
        width: 70px;
        height: 70px;
        padding: 10px 16px;
        font-size: 24px;
        line-height: 1.33;
        border-radius: 35px;
    }
}
</style>
