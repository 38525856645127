<template>
    <div>
        <table class="table table-bordered-list table-bordered col-md-12">
            <thead>
                <b-tr>
                    <b-th>STT</b-th>
                    <b-th class="text-center">Thanh toán</b-th>
                    <b-th class="text-center">Mô tả</b-th>
                    <b-th class="text-center">Ngày tạo</b-th>
                </b-tr>
            </thead>
            <tbody>
                <template v-for="(element) in items ">
                    <tr :key="element.id">
                        <td colspan="4">
                            <div>
                                <div class="w-50 d-flex px-4">
                                    <div class="font-weight-bold font-size-sm text-primary mr-3"><span></span>{{
                                        element.employeeCode }}
                                    </div>
                                    <div class="mr-3 font-size-lg font-weight-bold text-dark">
                                        {{ element.employeeName }}
                                    </div>
                                    <div class="font-size-sm">{{ element.jobTitleName }}</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <template v-for="(record, j) in element.stockDebtHistories">
                        <tr :key="record.id">
                            <td class="text-center ">{{ j + 1 }}</td>
                            <td class="text-center">
                                {{ record.paid }}
                            </td>
                            <td class="text-center w-30">
                                {{ record.description }}
                            </td>
                            <td class="text-center">
                                {{ formatDate(record.createdAt) }}
                            </td>

                        </tr>
                    </template>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { formatDate } from '@/utils/common';

export default {
    props: {
        inventoryCheckData: {
            type: Object,
        },
    },
    watch: {
        $props: {
            handler(newProps) {
                this.productStockCheckId =
                    newProps.inventoryCheckData.productStockCheck.id || null;
                this.fetchStockDebtHistories();

            },
            deep: true,
            immediate: false,
        },
    },
    data() {
        return {
            items: [],
            productStockCheckId: null,
        };
    },
    methods: {
        formatDate,
        fetchStockDebtHistories: function () {
            const params = {
                productStockCheckId: this.productStockCheckId,
            };
            ApiService.query(`/stockDebt/getStockDebtHistories`, { params }).then(
                (response) => {
                    this.items = response.data.data;
                },
            );
        },
    },
};
</script>

<style></style>
