<template>
  <div>
    <div class="mb-5">
      <b-row class="mb-3">
        <b-col md="4" v-if="disableSelectTypeSearch">
          <b-form-select v-model="selectTypeSearch" class="select-style" :options="listTypeSearch" size="sm"
            value-field="id" text-field="name" @change="changePlaceholder"></b-form-select>
        </b-col>
        <b-col>
          <b-form-input size="sm" v-model="textSearchProduct" @input="onInputChangeProduct" @keyup.enter="fetchData()"
            :placeholder="inputPlaceHolder">
            <template slot-scope="{ suggestion }">
              <div>{{ suggestion.item.productName }}</div>
            </template>
          </b-form-input>
        </b-col>
      </b-row>
    </div>
    <b-row class="mb-5">
      <b-col>
        <div v-if="productStockCheck.status === PRODUCT_STOCK_CHECK_STATUS.NEW && isChangeVersion">
          <b-nav class="nav-status" lage pills>
            <b-nav-item class="font-weight-bold" v-for="(status, index) in statusOptions" :key="index"
              @click="handleTabsStatus(status.value, index)" :active="index == tabIndex ? true : false">{{ status.text
              }}</b-nav-item>
          </b-nav>
        </div>
      </b-col>
      <b-col>
        <div class="d-flex d-inline justify-content-end mb-4"
          v-if="!isChangeVersion && productStockCheck.status !== PRODUCT_STOCK_CHECK_STATUS.NEW">
          <span class="mr-2">
            <i class="fas fa-circle fa-sm text-dark"></i>
            Tồn có thể bán
          </span>
          <span class="mr-2">
            <i class="fas fa-circle fa-sm text-warning"></i>
            Đang giữ hàng
          </span>
          <span class="mr-2">
            <i class="fas fa-circle fa-sm text-primary"></i>
            Đang bảo hành
          </span>
        </div>
      </b-col>
    </b-row>
    <b-table :items="items" :fields="fieldsComputed">
      <template v-slot:cell(barCode)="data">
        <div v-if="data.item.barCode !== ''">
          <b v-text="data.item.barCode"></b>
          <p class="productCode mt-1">
            <span v-text="data.item.productCode"></span>
          </p>
        </div>
      </template>
      <template v-slot:cell(stockInfo)="data">
        <div style="text-align: center">
          <b v-text="data.item.totalQuantityInStock" class="mr-1" v-b-tooltip.hover.bottom
            title="Tổng tồn có thể bán"></b>
          <b class="text-primary mr-1 font-size-setting" v-if="data.item.totalQuantityWarranty > 0"
            v-b-tooltip.hover.bottom title="Tổng đang bảo hành">
            {{ '+ ' + data.item.totalQuantityWarranty }}
          </b>
          <b class="text-warning font-size-setting" v-if="data.item.totalQuantityHolding > 0" v-b-tooltip.hover.bottom
            title="Tổng đang giữ hàng">
            {{ '+ ' + data.item.totalQuantityHolding }}
          </b>
        </div>
      </template>
      <template v-slot:cell(no)="data">{{ data.index + 1 }}</template>
      <template v-slot:cell(productName)="data">{{
        data.item.productName
      }}</template>
      <template v-slot:cell(realStockQuantity)="data">
        <b-form-input @change="onChangeRealStockQuantity(data)" v-if="
          productStockCheck.status === PRODUCT_STOCK_CHECK_STATUS.NEW
        " v-model="data.item.realStockQuantity" type="number" step="1"></b-form-input>
        <div v-else>{{ data.item.realStockQuantity }}</div>
      </template>
      <template v-slot:cell(difference)="data">
        <div v-if="calculateQuantityDiff(data.item) === 0">
          <i class="icon-check fa fa-check"></i>
        </div>
        <div class="font-weight-bold" :class="{
          'text-warning': calculateQuantityDiff(data.item) > 0,
          'text-danger': calculateQuantityDiff(data.item) < 0,
        }" v-else>
          {{ calculateQuantityDiff(data.item) }}
        </div>
      </template>
      <template v-slot:cell(btnUpdate)="data">
        <div v-if="productStockCheck.status === PRODUCT_STOCK_CHECK_STATUS.NEW" class="btn-update">
          <b-button v-if="!data.item.checked" size="sm">
            <i class="fa fa-check"></i></b-button>
          <b-button v-else variant="primary" size="sm">
            <i class="fa fa-check"></i></b-button>
        </div>
        <div v-else-if="productStockCheck.status === PRODUCT_STOCK_CHECK_STATUS.COMFIRM">
          <b-form-select class="input-style" @change="handleSelect(data)" :options="PRODUCT_CHECK_STATUS" size="sm"
            value-field="value" text-field="text" v-model="data.item.status">
          </b-form-select>
        </div>
        <div v-else>
          {{ data.item.statusName }}
        </div>
      </template>
    </b-table>
    <b-row>
      <b-col>
        <p class="mt-3 text-dark" style="font-weight: 500">
          Tổng số :
          {{ totalItem }}
        </p>
      </b-col>
      <b-col>
        <b-pagination-nav class="custom-pagination" v-if="numberOfPage >= 2" :link-gen="linkGen"
          :number-of-pages="numberOfPage" use-router @change="fetchData" align="right"
          first-class="page-item-first btn btn-icon btn-sm m-1" prev-class="page-item-prev btn btn-icon btn-sm m-1"
          next-class="page-item-next btn btn-icon btn-sm m-1 " last-class="page-item-last btn btn-icon btn-sm m-1 "
          page-class="btn btn-icon btn-sm border-0 m-1">
          <template v-slot:first-text>
            <span>
              <i class="ki ki-double-arrow-back icon-xs"></i>
            </span>
          </template>

          <template v-slot:prev-text>
            <i class="ki ki-arrow-back icon-xs"></i>
          </template>

          <template v-slot:next-text>
            <i class="ki ki-arrow-next icon-xs"></i>
          </template>

          <template v-slot:last-text>
            <span class="text-info">
              <i class="ki ki-double-arrow-next icon-xs"></i>
            </span>
          </template>
        </b-pagination-nav>
      </b-col>
    </b-row>
    <hr />
    <div v-if="checkPermission('STOCK_CHECK_CONFIRM')">
      <div
        v-if="productStockCheck.status === PRODUCT_STOCK_CHECK_STATUS.NEW || productStockCheck.status === PRODUCT_STOCK_CHECK_STATUS.COMFIRM">
        <b-button size="sm" variant="primary" class="font-weight-bolder mr-3" @click="showConfirmAlert()">
          {{ titleConfirm }}
        </b-button>
        <b-button v-if="productStockCheck.status === PRODUCT_STOCK_CHECK_STATUS.COMFIRM" class="font-weight-bold"
          variant="warning" size="sm" @click="showConfirmAlert('save-print')">
          {{ titleConfirm }} và in
        </b-button>
      </div>
      <div v-else>
        <b-button @click="updateProductStockCheck()" size="sm" variant="primary" class="font-weight-bolder mr-3">
          Lưu
        </b-button>
        <b-button class="font-weight-bold" variant="warning" size="sm" @click="updateProductStockCheck('save-print')">
          Lưu và in
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { PRODUCT_STOCK_CHECK_STATUS, PRODUCT_SEARCH_TYPE, PRODUCT_TYPE } from '@/utils/enum';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import decounce from 'debounce';
import { TIME_TRIGGER, PRODUCT_CHECK_STATUS } from '@/utils/constants';
import localData from '@/utils/saveDataToLocal';

export default {
  props: {
    mainModel: {
      type: Object,
    },
  },
  watch: {
    $props: {
      handler(newVal) {
        this.productStockCheck = newVal.mainModel.productStockCheck;
        if (this.productStockCheck.productType === PRODUCT_TYPE.PRODUCT) {
          this.disableSelectTypeSearch = false;
          this.selectTypeSearch = PRODUCT_SEARCH_TYPE.PRODUCT_NAME

        }
        if (this.productStockCheck.productType === PRODUCT_TYPE.PRODUCT_IMEI) {
          this.disableSelectTypeSearch = false;
          this.selectTypeSearch = PRODUCT_SEARCH_TYPE.IMEI_CODE
        }
        this.changePlaceholder()
        if (this.isChangeVersion) {
          this.items = newVal.mainModel.items;
          this.numberOfPage = newVal.mainModel.totalPage;
          this.totalItem = newVal.mainModel.totalItem;
        } else {
          if (this.searchProductImei || this.searchProduct || this.productStockCheck.status !== this.PRODUCT_STOCK_CHECK_STATUS.NEW) {
            this.items = newVal.mainModel.items;
            this.numberOfPage = newVal.mainModel.totalPage;
            this.totalItem = newVal.mainModel.totalItem;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      PRODUCT_STOCK_CHECK_STATUS,
      disableSelectTypeSearch: true,
      PRODUCT_SEARCH_TYPE,
      isChangeVersion: false,
      items: [],
      PRODUCT_CHECK_STATUS,
      textSearchProduct: '',
      inputPlaceHolder: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
      selectTypeSearch: 1,
      linkGen: (pageNum) => {
        return pageNum === 1 ? `?id=${this.productStockCheck.id}` : `?id=${this.productStockCheck.id}&pageNum=${pageNum}`;
      },
      listTypeSearch: [
        {
          id: PRODUCT_SEARCH_TYPE.PRODUCT_NAME,
          name: 'Tìm sản phẩm',
        },
        {
          id: PRODUCT_SEARCH_TYPE.IMEI_CODE,
          name: 'Tìm sản phẩm IMEI',
        },
      ],
      statusCheck: null, // default
      totalItem: 0,
      numberOfPage: 0,
      productStockCheck: { status: 1 }, // default
      statusOptions: [
        { value: null, text: 'Tất cả' },
        { value: 2, text: 'Đã kiểm tra' },
        { value: 1, text: 'Chưa kiểm tra' },
      ],
      searchProduct: '',
      searchProductImei: '',
      tabIndex: 0,
      fields: [
        {
          key: 'no',
          label: 'STT',
          tdClass: 'align-middle text-center',
          thClass: 'text-center',
          thStyle: { width: '5%' },
        },
        {
          key: 'barCode',
          label: 'Mã vạch',
          sortable: false,
          thStyle: { width: '15%' },
          tdClass: 'align-middle text-left',
          thClass: 'align-middle text-center',
        },
        {
          key: 'productImei',
          label: 'Imei',
          sortable: false,
          thStyle: { width: '12%' },
          tdClass: 'text-center align-middle text-primary',
          thClass: 'text-center',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          thClass: 'text-center',
          tdClass: 'align-middle text-left',
          thStyle: { width: '18%' },
        },
        {
          key: 'stockInfo',
          label: 'Tồn kho',
          tdClass: 'align-middle text-center',
          thClass: 'text-center',
          thStyle: { width: '15%' },
        },
        {
          key: 'realStockQuantity',
          label: 'Tồn thực',
          thClass: 'text-center',
          tdClass: 'align-middle text-center',
          thStyle: { width: '10%' },
        },
        {
          key: 'difference',
          label: 'Tồn lệch',
          tdClass: 'align-middle text-center',
          thClass: 'text-center',
          thStyle: { width: '10%' },
          tdStyle: {
            fontSize: '1.5rem',
          },
        },
        {
          key: 'btnUpdate',
          label: '',
          tdClass: 'align-middle text-center',
          thClass: 'text-center',
          thStyle: { width: '15%' },
        },
      ],
    };
  },
  methods: {
    saveProductStockCheck(mode = '') {
      const params = {
        id: Number(this.productStockCheck.id),
      };
      ApiService.put('productStockCheck/saveAndLock', params)
        .then((response) => {
          makeToastSuccess(response.data.message);
          if (mode === 'save-print') {
            this.$router.push({
              name: 'print-inventory-check',
              query: { id: this.productStockCheck.id },
            });
          } else {
            this.textSearchProduct = '';
            this.fetchData();

          }
        })
        .catch((error) => {
          if (error.response) {
            makeToastFaile(
              error.response.data ? error.response.data.message : 'Lỗi',
            );
          } else {
            makeToastFaile(error.message || 'Lỗi');
          }
        });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    updateProductStockCheck(mode = '') {
      const data = {
        productStockCheck: this.productStockCheck,
        productStockCheckItems: this.items
      }
      ApiService.put('productStockCheck', data)
        .then(({ data }) => {
          makeToastSuccess(data.message);
          if (mode === 'save-print') {
            this.$router.push({
              name: 'print-inventory-check',
              query: { id: this.productStockCheck.id },
            });
          }
        })
        .catch((error) => {
          makeToastFaile(error.response.data.message);
        });
    },
    changePlaceholder() {
      if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.PRODUCT_NAME) {
        this.searchProductImei = '';
        this.inputPlaceHolder =
          'Tìm kiếm theo tên, mã, mã vạch sản phẩm';
      } else if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.IMEI_CODE) {
        this.searchProduct = '';
        this.inputPlaceHolder =
          'Tìm kiếm theo mã IMEI sản phẩm';
      }
    },
    handleTabsStatus(status, indexTab) {
      this.tabIndex = indexTab;
      this.statusCheck = status;
      this.$route.query.pageNum = 1;
      this.fetchData();
    },
    updateProductStockCheckItem(dataRow) {
      const { item, index } = dataRow
      const realStockQuantity = item.realStockQuantity ? Number(item.realStockQuantity) : 0;
      if (!Number.isInteger(realStockQuantity) || realStockQuantity < 0) {
        makeToastFaile('Vui lòng nhập tồn thực là số nguyên dương!');
        return;
      }
      if(item.productType ===  PRODUCT_TYPE.PRODUCT_IMEI){
        if(realStockQuantity > 1) {
          makeToastFaile('Sản phẩm imei không được lón hơn 1!');
          return;
        }
      }
      const payloadUpdate = { ...item, productStockCheckId: Number(this.productStockCheck.id) }
      ApiService.post('productStockCheckItem', payloadUpdate)
        .then((response) => {
          const { message } = response.data;
          this.items[index].checked = true;
          makeToastSuccess(message);
        })
        .catch((error) => {
          if (error.response) {
            makeToastFaile(error.response.message);
          }
          makeToastFaile(error.message);
        });
    },
    showConfirmAlert: function (mode = '') {
      Swal.fire({
        title: `${this.titleConfirm} kiểm kê!`,
        text: `${this.titleConfirm} kiểm kê sẽ khóa và không thể tiếp tục kiểm kê ${this.titleConfirm === 'Xác nhận' ? 'hay tạo mới phiếu trong ngày hôm nay' : ''}, bạn có chắc chắn muốn ${this.titleConfirm} ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xác nhận',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.saveProductStockCheck(mode)
        }
      });
    },
    fetchData() {
      if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.PRODUCT_NAME) {
        this.searchProduct = this.textSearchProduct
        this.searchProductImei = '';
      } else {
        this.searchProductImei = this.textSearchProduct
        this.searchProduct = '';
      }
      this.$emit('fetchData', this.searchProduct, this.searchProductImei, this.statusCheck,);
    },
    calculateQuantityDiff(data) {
      return (
        data.realStockQuantity -
        (data.totalQuantityInStock +
          data.totalQuantityHolding +
          data.totalQuantityWarranty)
      );
    },
    onChangeRealStockQuantity(dataRow) {
      this.updateProductStockCheckItem(dataRow)
    },
    onInputChangeProduct() {
      if (this.textSearchProduct === '') {
        this.debounceInputProduct();
      }
      if (this.textSearchProduct.length < 6) {
        return;
      }

      this.debounceInputProduct();
    },
    debounceInputProduct: decounce(function () {
      this.fetchData();
    }, TIME_TRIGGER),
    handleSelect(selectedProduct) {
      this.updateProductStockCheckItem(selectedProduct)
    }
  },
  components: {
  },
  computed: {
    fieldsComputed() {
      if (this.isChangeVersion || this.productStockCheck.status !== PRODUCT_STOCK_CHECK_STATUS.NEW) {
        return this.fields
      }
      if (this.productStockCheck.prodductType === PRODUCT_TYPE.PRODUCT) {
        this.fields.filter(field => {
          return field.key !== 'productImei'
        })
      }
      return this.fields.filter(field => {
        return field.key !== 'difference' && field.key !== 'stockInfo';
      });
    },
    titleConfirm() {
      return this.productStockCheck.status === PRODUCT_STOCK_CHECK_STATUS.NEW ? 'Hoàn tất' : 'Xác nhận'
    },
  },
};
</script>

<style scoped>
.nav-status .nav-link {
  color: black;
  font-size: 1.1rem;
}
</style>
