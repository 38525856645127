<template>
    <div>
        <b-modal ref="add-stock-debt-modal" title="Tạo truy thu" hide-footer>
            <b-card bg-variant="light" text-variant="dark" class="mb-5">
                <b-card-text>
                    <b-form>
                        <b-form-group label="Truy thu:" label-for="debt">
                            <b-form-input v-model="mainModal.debt" :state="validateDebt" id="debt"></b-form-input>
                            <b-form-invalid-feedback :state="validateDebt">
                                Vui lòng nhập giá truy thu !
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </b-form>
                </b-card-text>
            </b-card>
            <hr />
            <div class="d-flex justify-content-end">
                <b-button class="mr-3" @click="hideModal()">Hủy</b-button>
                <b-button variant="primary" @click="submitForm()">Tạo</b-button>
            </div>
        </b-modal>
    </div>
</template>
  
<script>
import ApiService from '@/core/services/api.service';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import { unMaskMoney } from '@/utils/common';

export default {
    props: {
        productStockCheckItem: {
            type: Object
        }
    },
    data() {
        return {
            mainModal: {}
        };
    },
    watch: {
        $props: {
            handler(newProps) {
                this.mainModal = { ...newProps.productStockCheckItem, debt: newProps.productStockCheckItem.debt ? newProps.productStockCheckItem.debt : this.getTotalAmoutProduct(newProps.productStockCheckItem) }
            },
            deep: true,
            immediate: false,
        },
    },
    methods: {
        showModal() {
            this.$refs['add-stock-debt-modal'].show();
        },
        hideModal() {
            this.$refs['add-stock-debt-modal'].hide();
        },
        getQuantityProdct(product) {
            return (
                product.realStockQuantity -
                (product.totalQuantityInStock +
                    product.totalQuantityHolding +
                    product.totalQuantityWarranty)
            );
        },
        getTotalAmoutProduct(productCheckItem) {
            return (
                Math.abs(this.getQuantityProdct(productCheckItem)) *
                productCheckItem.productPrice
            );
        },
        updateProductStockCheck() {
            const data = { ...this.mainModal, debt: unMaskMoney(this.mainModal.debt) };
            ApiService.post('productStockCheckItem', data)
                .then((response) => {
                    const { message } = response.data;
                    makeToastSuccess(message);
                    this.$emit('addStockDebt')
                })
                .catch(({ response }) => {
                    makeToastFaile(response.$error);
                });
        },
        submitForm() {
            if (!this.mainModal.debt) {
                makeToastFaile('Vui lòng nhập truy thu!');
                return;
            }
            this.updateProductStockCheck()
            this.hideModal()
        },

    },
    computed: {
        validateNote() {
            return !!this.mainModal.note
        },
        validateDebt() {
            return !!this.mainModal.debt
        }
    }
};
</script>
  
<style>
.input-style {
    border: 1px solid #ced4da;
}

.form-group label {
    font-weight: 500;
}
</style>
  